


























/*
* 작은 화면일때 글씨가 잘리는 경우 대응
* */
import { EventBus } from '@/config/config';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component
export default class ReserveBeforeCheck extends Vue {
  @Prop({
    required: true
  })
  public state!: boolean;
  @Prop()
  public list!: any;
  public isAllCheck: boolean = false;
  public loading: boolean = false;

  public mounted() {
    this.allCheck();
    EventBus.$on('reserveBeforeCheckLoading', (data: any) => {
      this.loading = data;
    });
  }

  public allCheck() {
    this.isAllCheck = true;
    if (this.list && this.list.length) {
      this.list.filter((row: any) => {
        if (!row.active) {
          this.isAllCheck = false;
        }
      });
    }
  }

  public confirmClick() {
    if (this.isAllCheck) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.$emit('update');
    }
  }
}
